@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin blank-btn($width, $height, $color, $bgc, $fz, $border, $svg-margin: 0) {
  color: $color;
  width: $width;
  height: $height;
  cursor: pointer;
  outline: none;
  background: $bgc;
  font-size: $fz;
  display: flex;
  align-items: center;
  justify-content: center;
  border: $border;
  border-radius: 7px;

  svg {
    margin-right: $svg-margin;
  }
}


// Respond above.
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

/* respond-above Example

@include respond-above(sm) {
  .element {
    font-weight: bold;
  }
}
 */

@mixin create-hierarchy-modal($height: 55vh) {
  .name {
    border-bottom: 1px solid #e3e3e3;
  }

  .organizations-container {
    height: $height;
    overflow-y: scroll;

    .subtitle {
      font-size: 14px;
      color: #333333;
      font-weight: 100;
    }

    .organizations {
      margin-top: 10px;

      .organization {
        padding: 20px;
        border-radius: 15px;
        transition: .4s;

        .custom-checkbox {
          .custom-control-input {
            cursor: pointer;
          }
        }

        .permissions {
          background-color: #ffffff;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          max-height: 0;
          transition: .4s;
          padding: 0 25px;

          .custom-checkbox {
            margin-top: 30px;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .selected {
        background-color: #F7F7F7;

        .permissions {
          padding: 25px 25px;
          max-height: 400px;
        }
      }
    }
  }


  .modal-footer {
    .next-btn {
      border-radius: 10px;
      width: 100%;
      height: 65px;
      background: linear-gradient(270deg, #7BD58A 13.66%, #44BF96 90.51%);
      outline: none;
      cursor: pointer;
      border: none;
      font-size: 18px;
      color: #ffffff;

      &:disabled {
        opacity: .7;
      }
    }
  }
}

@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

/* respond-below Example:

@include respond-below(sm) {
 .element {
   font-weight: bold;
 }
}
*/

@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin register-buttons() {
  margin-top: 48px;
  width: 100%;

  .back-button {
    width: 68px;
    height: 68px;
    border: 2px solid #06A3AE;
    border-radius: 14px;
    cursor: pointer;
    outline: none;
    background-color: #FFFFFF;
    transition: .4s ease;
    margin-right: 18px;

    &:hover {
      background-color: #06A3AE;

      svg {
        path {
          fill: #FFFFFF;
        }
      }
    }
  }

  .cont-button {
    border: none;
    width: calc(100% - 86px);
    height: 68px;
    border-radius: 14px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    background: linear-gradient(270.06deg, #06A3AE 9.78%, #008C96 96.75%);
  }
}

@mixin devices-text-kits() {
  main {
    padding: 24px 48px;
    width: 100%;
    min-height: calc(100vh - 46px);

    .title {
      font-size: 40px;
      color: #5a5a5a;
      margin-bottom: 36px;
    }

    .advanced-btn {
      width: 350px;
      height: 100px;
      background-color: #e9effc;
      border: none;
      border-radius: 7px;
      cursor: pointer;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 30px;
      transition: .2s ease;
      margin-bottom: 36px;

      p {
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 20px;
        color: #888888;
      }

      &:hover {
        -webkit-box-shadow: 0 .5rem 1rem rgba(30, 30, 30, .15);
        -moz-box-shadow: 0 .5rem 1rem rgba(30, 30, 30, .15);
        box-shadow: 0 .5rem 1rem rgba(30, 30, 30, .15);
      }
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .subtitle {
        font-size: 20px;
        color: #888888;
        margin: 0;
      }

      .buttons {
        display: flex;
        align-self: center;

        button {
          width: 135px;
          height: 36px;
          border: none;
          border-radius: 7px;
          color: #ffffff;
          background-color: #06a3ae;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .order-btn {
          background: linear-gradient(270deg, #7BD58A 13.66%, #44BF96 90.51%);
          margin-left: 15px;
        }
      }
    }

    .table-container {
      width: 100%;
      overflow-x: auto;
      transform: rotate(180deg);
      padding-top: 90px;
    }

    .mobile-table {
      display: none;
    }
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

@mixin cell() {
  height: 38px;

  .label {
    font-size: 12px;
    color: #BDBDBD;
    margin-bottom: 2px;
  }

  .value {
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }

  &.origin {
    .value {
      display: flex;
      align-items: center;

      img {
        width: 15px;
      }

      p {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }

  &.progress {
    display: flex;
    flex-direction: column;

    .progress-container {

      .progress {
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        background-color: transparent;

        p {
          margin: 0;
        }

        &.in-stock {
          border: solid 1px #7BD58A;

          p {
            color: #7BD58A;
          }
        }

        &.out-of-stock {
          border: solid 1px #ff0404;

          p {
            color: #ff0404;
          }
        }
      }

      .almost-icon {
        height: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@mixin devices-test-kits-mobile-table() {
  main {
    width: 100%;
    padding-bottom: 30px;

    .item {
      width: 100%;
      background-color: #F9F9FA;
      padding: 20px 20px 30px;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      .buttons {
        display: flex;
        align-self: flex-end;
        align-items: center;
        margin-bottom: 20px;

        .order-btn, .update-btn {
          width: 75px;
          height: 32px;
          border: 1px solid #E0E0E0;
          border-radius: 7px;
          background-color: transparent;
          color: #68686D;
          font-size: 12px;
          cursor: pointer;
          outline: none;
          margin-right: 15px;
        }

        .delete-btn {
          height: 32px;
          border: none;
          background-color: transparent;
          cursor: pointer;
          outline: none;
        }
      }

      .name {
        text-transform: capitalize;
        font-size: 16px;
        color: #333333;
        margin-bottom: 15px;
      }

      .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .cell-container {
          width: calc(100% / 3);

          &.center {
            display: flex;
            justify-content: center;
          }

          &.right {
            display: flex;
            justify-content: flex-end;
          }

          .cell {
            @include cell();
          }
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .cell-container {
          .cell {
            @include cell();
          }

          &.center {
            display: flex;
            justify-content: center;
          }

          &.right {
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


