@import "variables";
@import "../node_modules/bootswatch/dist/minty/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootswatch/dist/minty/bootswatch";
@import "../node_modules/bootswatch/dist/minty/bootstrap.min.css";
@import "elevation";
@import "./mixins/mixins.scss";

html, body {
  height: 100%;
  min-height: 100vh;
}

.middle {
  > * {
    vertical-align: middle;
  }
}

.panel-enabled {
  border-bottom: none !important;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  vertical-align: bottom;
  white-space: nowrap;
  display: inline-block;
}

.text-overflow-100 {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  vertical-align: bottom;
  white-space: nowrap;
  display: inline-block;
}

.text-overflow-100-60 {
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 60px);
  vertical-align: bottom;
  white-space: nowrap;
  display: inline-block;
}

.f-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fz-14 {
  font-size: 14px;
}

.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 14px;
  background-color: rgb(243 243 243);
}

.scrollbar::-webkit-scrollbar {
  height: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #a4a4a4;
  border-radius: 10px;
}


.block-loader {
  width: 100%;
  height: calc(100% - 47px);
  background: #cbcbcb57;
  z-index: 99999;
  position: fixed;
  top: 47px;
  right: 0;
}

.scroll-dots-loader {
  width: 100%;
  height: 40px;
}

.nav-tabs {
  .tab {
    width: 50%;
  }
}
