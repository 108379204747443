
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f7f7f9 !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #aaa !default;
$gray-600: #888 !default;
$gray-700: #5a5a5a !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #1e1e1e !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #FF7851 !default;
$orange:  #fd7e14 !default;
$yellow:  #FFA552 !default;
$green:   #56CC9D !default;
$teal:    #20c997 !default;
$cyan:    #6CC3D5 !default;

$primary:       #06A3AE !default;
$secondary:     #52ABB3 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-300 !default;
$dark:          $gray-800 !default;

$light-grey: #9DA0A6 !default;
$light-black: #333333 !default;

// Body
$body-color:                $gray-600 !default;

// Components

$border-radius:               .5rem !default;
$border-radius-lg:            $border-radius * 1.25 !default;
$border-radius-sm:            $border-radius * 0.75 !default;

// Fonts
$font-family-base:            Rubik, "Apex New Book", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$headings-font-family:        Rubik, "Apex New", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$text-body: #333 !default;

$popover-font-size: 1rem;

$breakpoints: (
  xxs: 576px,
  xs: 650px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1500px
);

//Chekcboxes fix
.custom-checkbox .custom-control-label::before {
  border-radius: $border-radius-sm;
}

.navbar-light {
  background-color: $white;
}

.navbar-toggler-icon {
  // Bootstrap uses a custom toggler icon via css. We can only alter it's color by changing the stroke in the svg.
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
